<template>
  <div>
    <label v-if="label" :for="id">
      {{ label }}
      <span v-if="required" class="required">*</span>
    </label>

    <Multiselect
      track-by="value"
      label="label"
      :class="{ 'is-invalid': error }"
      :id="id"
      :value="multiselectValue"
      v-bind="multiselect"
      v-on="multiselectOn"
      @input="$emit('input', $event && $event.value)"
    />

    <div class="invalid-feedback">
      {{ error }}
    </div>
  </div>
</template>

<script>
import find from 'lodash/find';

import BaseField from './BaseField';
import Multiselect from '../vue-multiselect/Multiselect.vue';

export default {
  extends: BaseField,

  components: { Multiselect },

  props: {
    multiselect: { type: Object, default: () => ({}) },
    multiselectOn: { type: Object },
  },

  computed: {
    multiselectValue() {
      return find(this.multiselect.options, ['value', this.value]);
    },
  },
};
</script>
